// syntax colors
$base-color: #DDF0FF;

$keyword-color: #e37933;
$function-color: #06AED5;
$special-character-color: #a8b6be;
$comment-color: #4d5a5e;
$string-color: #8dc149;
$l-value-color: #cbcb41;
$r-value-color: #a074c4;

$tag-color: #8dc149;
$attr-name-color: #f55385;
$attr-value-color: #519aba;

// background color
$primary-color-1: #252933;
$primary-color-2: #2b2b2b;