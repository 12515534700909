@import '../constants/var';
@import '../constants/mixin';
@import './syntaxHighlighting';

$space-width : 7.15px;
$line-height: 15px;


.code-block-root {
    pre {
        margin: 0 !important;
    }
    
    padding: 20px;
    font-size: 14px;

    .code-block-wrapper {
        color: white;
        margin: auto;
        max-width: 50rem;
        background-color: #151718;

        .code-block-top-bar {
            display: flex;
            align-items: center;
            padding-top: 12px;
            margin: 12px;

            title {
                display: inline-block;
                min-height: 19px;
            }

            .circle-container {
                display: grid;
                grid-template-columns: 12px 12px 12px;
                column-gap: 5px;
                position: absolute;
            }

            title {
                display: flex;
                justify-content: center;
                width: 100%;
                overflow: hidden;
            }
        }

        .code-block-body-container {
            display: flex;
            min-height: 5rem;
            background-color: $primary-color-2;
            
            .line-numbers,
            .right-border {
                padding: 6px;
                display: flex;
                flex-direction: column;
                font-family: monospace;
                text-align: right;
                @include noselect;
            }

            .code-block-body {
                width: 100% !important;
                color: $base-color;
                padding: 6px;
                font-family: monospace;
                @include scrollbar;

                display: flex;
                flex-direction: column;

                width: max-content;
                overflow: scroll;
                scroll-padding-left: 6px;
                .code-line {
                    span {
                        display: inline-block;
                        white-space: pre;
                    }

                    @include syntax-colors;
                }
            }
        }
    }
}